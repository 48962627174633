import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  CalculatePaymentProductAddonInput,
  CalculatePaymentPackageInput,
} from "@trnsact/trnsact-shared-types/dist/generated";
import { Rate } from "@trnsact/ntp-sdk";
import "./AftermarketNtpProductComponent.css";
import NTP_LOGO from "../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import { formatCurrency, formatNumberWithDelimiter } from "utils";
import DraggableComponent from "components/DragAndDrop/DraggableComponent";

interface AftermarketNtpProductComponentProps {
  jsonData: {
    API_RESPONSE_DATA: Rate;
  };
}

const AftermarketNtpProductComponent: React.FC<AftermarketNtpProductComponentProps> = ({ jsonData }) => {
  const [data, setData] = useState(jsonData);
  const [selectedDeductible, setSelectedDeductible] = useState<number | null>(null);
  const [selectedMileage, setSelectedMileage] = useState<any | null>(null);
  const [mappedData, setMappedData] = useState<CalculatePaymentPackageInput | null>(null);

  useEffect(() => {
    mapDataToDraggableComponent();
  }, [selectedMileage]);

  const uniqueDeductibles = _.uniqBy(data.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
    term => term.deductible.amount
  );

  const handleDeductibleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAmount = parseInt(event.target.value, 10);
    setSelectedDeductible(selectedAmount);
    setSelectedMileage(null);
  };

  const filteredMileageOptions = selectedDeductible
    ? data.API_RESPONSE_DATA.terms.filter(term => term.deductible.amount === selectedDeductible)
    : [];

  const handleMileageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = parseInt(event.target.value, 10);
    const selectedTerm = filteredMileageOptions[selectedIndex];
    setSelectedMileage(selectedTerm);
  };

  const mapDataToDraggableComponent = () => {
    if (!selectedMileage) return;

    const newMappedData: CalculatePaymentPackageInput & { apiData: any } = {
      name: data.API_RESPONSE_DATA.description,
      products: [
        {
          proposalProductId: "",
          retailCost: selectedMileage.dealerCost,
          title: "Options",
          cost: selectedMileage.dealerCost,
          addons: selectedMileage.components.flatMap((component: any) =>
            component.lossCodes
              .filter((lossCode: any) => lossCode._pqtSelected)
              .map((lossCode: any) => ({
                category: component.description,
                cost: lossCode.dealerCost,
                title: lossCode.description,
                retailCost: lossCode.suggestedRetailCost,
              }))
          ),
        },
      ],
      apiData: data,
      proposalPackageId: data.API_RESPONSE_DATA.description,
    };

    setMappedData(newMappedData);
  };

  const handleCheckboxChange = (componentIndex: number, lossCodeIndex: number, isSelected: boolean) => {
    if (!selectedMileage) return;

    const updatedData = _.cloneDeep(data);

    updatedData.API_RESPONSE_DATA.terms.forEach(term => {
      if (term.termMonths === selectedMileage.termMonths && term.termOdometer === selectedMileage.termOdometer) {
        // Update the specific checkbox for the selected component and lossCode
        // @ts-expect-error - done as a temp solution
        term.components[componentIndex].lossCodes[lossCodeIndex]._pqtSelected = isSelected;

        setSelectedMileage(term);
      }
    });

    setData(updatedData);
  };

  return (
    <div className="draggable-container">
      <div className="header">
        <img className="logo" src={NTP_LOGO} alt="NTP Logo" />
        <div className="description">{data.API_RESPONSE_DATA.description}</div>
      </div>

      <div className="select-container">
        <div className="select-group">
          <label>Deductible:</label>
          <select value={selectedDeductible || ""} onChange={handleDeductibleChange}>
            <option value="" disabled>
              Select Deductible
            </option>
            {uniqueDeductibles.map((amount, index) => (
              <option key={index} value={amount}>
                {formatCurrency(amount)}
              </option>
            ))}
          </select>
          {selectedDeductible && (
            <>
              <label>Mileage:</label>
              <select
                value={selectedMileage ? filteredMileageOptions.indexOf(selectedMileage) : ""}
                onChange={handleMileageChange}
              >
                <option value="" disabled>
                  Select Mileage
                </option>
                {filteredMileageOptions.map((term, index) => (
                  <option key={index} value={index}>
                    {term.termMonths} Months / {formatNumberWithDelimiter(term.termOdometer)} Miles
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>

      {selectedMileage && (
        <DraggableComponent
          data={mappedData}
          draggableComponent={
            <>
              <div
                className="description"
                draggable
                onDragStart={e => {
                  const clonedData = _.cloneDeep(data);
                  e.dataTransfer.setData("text/plain", JSON.stringify(clonedData));
                }}
              >
                {/* <div style={{ fontSize: "18px", marginLeft: "64px" }}>
                  {selectedMileage.termMonths} Months / {formatNumberWithDelimiter(selectedMileage.termOdometer)} Miles -{" "}
                  {formatCurrency(selectedMileage.dealerCost)}.00
                </div> */}
              </div>

              <div className="components-container">
                {selectedMileage.components.map((component: any, componentIndex: number) => (
                  <div key={component.description} className="draggable-box">
                    <b>{component.description}</b>
                    <ul>
                      {component.lossCodes.map((lossCode: any, lossCodeIndex: number) => (
                        <li key={lossCode.coverageLossCodeId}>
                          <label>
                            <input
                              type="checkbox"
                              checked={lossCode._pqtSelected || false}
                              onChange={e => handleCheckboxChange(componentIndex, lossCodeIndex, e.target.checked)}
                            />
                            {lossCode.description}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default AftermarketNtpProductComponent;
